.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

.main_loader {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: .94;
    overflow: hidden;
    z-index: 99999;
}

.error {
    color: red;
}


  .fa{
      padding: 2px;
  }

.add-footer-textarea {
  //@extend .description-textarea;
  width: 100%;
  border: 1px solid #757575 !important;
  text-align: center;
  padding: 5px;
  margin-bottom: 15px;
}

.cell-wrap-text {
  white-space: normal !important;
}

.extra-width{
  .ant-select-dropdown{
    width: 250px !important;
  }
}

