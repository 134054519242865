@import '~antd/dist/antd.css';
@import '../common.scss';
@import '../../../public/sass/variable';

%list-flex {
  display: flex;
  align-items: center;
  list-style-type: none;
}

.header-wrapper {
  background: $headerColor;
  color: $headerText;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 9999;

  i {
    cursor: pointer;
    color: inherit;
  }

  .header-option {
    border-left: 1px solid $borderColor;
    color: $headerText;
    line-height: 20px;
    list-style-type: none;
    padding: 10px 16px;

    &:hover,
    &:active,
    &:focus {
      color: $headerHoverText;
      cursor: pointer;
    }
  }

  .menu-option-wrapper {
    position: relative;
  }

  .header-right-section,
  .header-left-section {
    @extend %list-flex;
  }

  .header-left-functions,
  .header-right-functions {
    @extend %list-flex;
    margin-bottom: 0;
  }

  .header-right-functions {
    margin-left: auto;
  }
}

.ant-menu-root {
  height: 100vh;
}

.header-right-functions {
  .header-option {
    position: relative;

    .user-dropdown {
      border: 1px solid #f0f0f1;
      border-radius: 4px;
      display: none;
      width: 360px;

      .user-header {
        background-color: #F3F8FE;
        color: #272727;
        padding-top: 30px;
        padding-bottom: 30px;
        position: relative;
        text-align: center;

        .close {
          cursor: pointer;
          font-size: 20px;
          opacity: .7;
          position: absolute;
          top: 15px;
          right: 15px;

          &:hover {
            opacity: 1;
          }
        }

        .my-account-logout {
          .link {
            color: #478BCA;
            cursor: pointer;
            position: relative;

            &:after {
              content: '';
              position: absolute;
              top: 3px;
              right: -20px;
              background-color: #656b79;
              width: 1px;
              height: 15px;
            }
          }

          .logout {
            color: #E88F83;
            cursor: pointer;
          }
        }
      }

      .organisation-info {
        background-color: #fff;
        color: #656b79;
        font-size: 13px;

        .title {
          text-transform: capitalize;
        }

        .link {
          color: #478BCA;
        }
      }

      .user-footer {
        border-top: 1px solid #d8dcde;
        padding: 10px;
        text-align: center;

        ul {
          color: #656b79;
          display: flex;
          justify-content: center;

          li {
            cursor: pointer;
            list-style-type: disc;
            margin-right: 15px;
            padding-right: 15px;
            padding-left: 15px;

            &:first-child {
              list-style-type: none;
            }

            &:last-child {
              margin-right: 0;
              padding-left: 0;
            }
          }
        }
      }
    }

    &.active {
      .user-dropdown {
        display: inline-block;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 18px rgba(0, 0, 0, .5);
        cursor: default;
        position: absolute;
        top: 37px;
        right: 20px;

        &:after {
          content: '';
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid #fff;
          position: absolute;
          top: -5px;
          right: 5px;
        }
      }
    }
  }
}
