// global scss
// @import "global/gutters";
// @import "global/variable";
// @import "global/mixin";
// @import "global/button";
// @import "global/ant-table";
// @import "global/component";
// @import "global/tabs";
// @import "common/custom_table";
// @import "common/button_dropdown";
// @import "common/custom_modal";
// @import "common/input-control";

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: 'Segoe UI', 'Droid Sans', Tahoma, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

::selection {
  background: #31bdbc;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  border-radius: 6px;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

a {
  color: #4697ce;
}

.w-100 {
  width: 100% !important;
}

// .text-red {
//   color: $error-red;
// }

.view-container {
  border-radius: 6px;
  background: #fff;
  margin-top: 15px;
  padding: 5px 0 15px 0;

  &.mt0 {
    margin-top: 0;
  }

  &.mt-20 {
    margin-top: -20px;
  }
}

.page-title {
  color: #000;
  font-size: 18px;
  text-align: center;
}

.vab {
  vertical-align: bottom;
}

.mt-5 {
  margin-top: -5px;
}

.right-align {
  display: flex;
  justify-content: flex-end;
}

.ant-modal-wrap {
  z-index: 9999;
}

.ant-notification {
  z-index: 99999 !important;
}

.ant-popover-placement-top {
  z-index: 9999;
}

.ant-popover {
  z-index: 9999;
}

.ant-tabs {
  overflow: unset;
}


.ant-btn-primary {
  background-color: #2c7045 !important;
  border-color: #2c7045 !important;

  &:hover, &:focus {
    background-color: #4ca185 !important;
    border-color: #51a35d !important;
    color: #fff !important;
  }

  > span {
    color: #fff;
  }
}

.amount-input {
  &:focus {
    border-color: green
  }
}

.ant-drawer {
  .form-footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ddd;
    padding-top: 20px;
    margin-left: -25px;
    margin-right: -20px;
    padding-left: 25px;
    padding-right: 20px;
  }
}

.dark-blue{ background: #1f2029; height: 100vh; position: relative;}
.dark-blue .brand-logo {width: 100px; height: 100px; border-radius: 50%; overflow: hidden; margin: 20px auto 0px;}
.dark-blue .brand-logo img{ width: 100%; }

.dark-blue button{   
  background: #4e4f5e;
  // background: #2d2e3a;
  // background-image: linear-gradient(298deg, #2d2e3a, #4c4f64);
   box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, .2); 
  border: 0px; border-radius: 4px; padding: 0px 0px; 
  height: 60px; width: 90%;  text-align: center;
color: #fff; margin: 10px 0px 10px 8px; font-size: 20px;}
.dark-blue button:hover{  background: #2c7045 !important;
  // background-image: linear-gradient(298deg, #4c4f64, #2d2e3a); 
  color: #fff;}
  .donation-bg{  
     background: url(../images/donation-bg1.jpg); 
     background-size: cover; height: 100vh;
    //  background: #f2f2f2;
  }
.buttom-logo{ position: absolute; width: 100%; text-align: center; left: 0px; bottom: 0px;}
 .dark-blue button:hover, button:focus, button:active, button.active{ 
  background: #2c7045 !important;
  //  background-image: linear-gradient(298deg, #4c4f64, #2d2e3a) !important; 
  //  color: #fff !important;
  }

.ant-divider{background: #363749 !important; margin: 10px 0px !important; 
  z-index: 0; position: relative;}

.dark-ins{ background: #242532; height: 100vh; 
    position: relative; color: #a0a2c0; 
    padding: 15px 0px 10px;}

.dark-ins ul{ padding-top: 70px; font-size: 20px;  list-style: none;}
 .dark-ins ul li{ list-style: none; padding: 10px 25px; border-bottom: solid 1px #363749;}
.dark-ins div{padding-left: 25px;}
.dark-ins ul li:hover{ background: #5c5f66; cursor: pointer; color: #fff;}
.active-prject{ background: #5c5f66; color: #fff;}
.active-prject-ple{ background: #5c5f66; color: #fff;}

.dark-ins ul label{ margin: 0px; padding: 0px 15px; font-size: 20px;}

.dark-ins .ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #f8ab37;
  border-radius: 50%;
  visibility: hidden;
  -webkit-animation: antRadioEffect 0.36s ease-in-out;
  animation: antRadioEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  content: '';
}

.dark-ins .ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 8px;
  height: 8px;
  background-color: #da2c4d;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}

.dark-ins.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
  border-color: #f8ab37;
}


.calco{ background: #eff8ff; height: 100vh; padding: 15px; overflow: auto;} 
.culb{ padding: 12px; text-align: center;}
.culb button{     color: #59627d; background-color: initial;
  // background-image: linear-gradient(298deg, #2d2e3a, #4c4f64);
  // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, .2); 
border: solid 1px #a1b3e1 !important; 
 border: 0px;  padding: 0px 0px;  font-size: 30px;
 height: 55px; width: 100%;  text-align: center;
 margin: 0px 0px 0px 8px; border-radius: 4px; }
.culb button:hover{ 
  background: #eff8ff;
//   background-image: linear-gradient(298deg, #2d2e3a, #4c4f64);
//  color: #fff;
}

.culb button .doloers{ position: relative; top: -15px; font-size: 14px;}


 .culbs{ padding: 0px; text-align: center;}
.culbs button{  
  background: #45806c;
  
  // background-image: linear-gradient(298deg, #2d2e3a, #4c4f64);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, .2); 
 border: 0px; border-radius: 4px; padding: 0px 0px; 
 height: 56px; width: 100%;  text-align: center; font-size: 20px;
color: #fff; margin: 10px 0px 0px 0px; border-radius: 4px;}
.culbs button:hover{ 
  background: #4ca185; 
  // background-image: linear-gradient(298deg, #da2c4d, #f8ab37); 
 color: #fff;}

 .culbb{ padding: 12px; text-align: center;}
.culbb button{  background: initial;
  // background-image: linear-gradient(298deg, #da2c4d, #f8ab37);  
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, .2); 
 border: 0px; border-radius: 4px; padding: 0px 0px; 
 height: 40px; width: 100%;  text-align: center;
color: rgb(68, 68, 68); margin: 0px 0px 0px 8px; border-radius: 4px; font-size: 14px;}
.culbb button:hover{ 
  // background-image: linear-gradient(298deg, #2d2e3a, #4c4f64);  
  background: #4ca185;
 color: #fff;}

.cul-text{ text-align: center;  
  font-size: 33px; padding: 2px;
  
  // border: solid 1px #dee3eb; border-radius: 6px; display: block;cursor: pointer;
}
.cul-text span{ padding: 0px; 
  // border: solid 1px #dee3eb; 
  border-radius: 6px; display: block;cursor: pointer;}
// .cul-text span:hover{ 
//    background: #fff;
// }
.mart { width: 100% !important; 
  background: none !important;  background-color: initial !important;
  border: 0px !important;
  margin: 0px 0px 10px !important; text-align: center !important;
  height: 50px; 
  padding: 0px 10px !important; 
  border-bottom: solid 1px #cecece !important;
  font-size: 33px !important;  line-height: 50px !important; border-radius: 0px !important;}
  .martm { width: 100% !important; 
    background: none !important;  background-color: initial !important;
    border: 0px !important;
    margin: 10px 0px 10px !important; text-align: left !important;
    height: 50px; 
    padding: 0px 10px !important; 
    border-bottom: solid 1px #cecece !important;
    font-size: 16px !important;  line-height: 26px !important; border-radius: 0px !important;}
  .ant-input-number-input{ text-align: center !important; font-size: 30px;}
 .ant-btn:hover, .ant-btn:focus, .ant-btn:active, .ant-btn.active{
  background: #2c7045 !important;
  color: #fff !important;
  //  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  }
  .back-arow{ background: url(../images/back-arrow.png) no-repeat left center; 
    margin: 16px auto 0px; 
    height: 20px; width: 20px;}
.white-bgs{ width: 90%; float: right;}
.submit-buton{background: #2c7045 ; color: #fff;}
.submit-buton:hover{background: #4ca185 ; color: #fff;}
.slick-slide img{ 
  //  height: 100%;
 width: 100%;
}

.done-popup{
  background: #0ba685;
  // background-image: linear-gradient(#0ba383, #09a987);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  width: 108.8%;
margin: -16px 0px 0px -24px;
}

.done-popup .checkmark {
  border-radius: 50%;
  box-shadow: inset 0 0 0 #16a085;
  display: block;
  height: 56px;
  margin: 20px auto;
  stroke: #0ba685;
  stroke-miterlimit: 10;
  stroke-width: 2;
  width: 56px;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.done-popup .checkmark-circle {
  fill: none;
  stroke: #fff;
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-miterlimit: 10;
  stroke-width: 2;
  animation: checked 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.done-popup .checkmark-check {
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  transform-origin: 50% 50%;
  animation: checked 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}


$green: #fff;
$white: #fff;

// Misc
$curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);



.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: $white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $green;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $green;
  fill: none;
  animation: stroke .6s $curve forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .3s $curve .8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $green;
  }
}

.done-close{background: #2c7045; padding: 10px 40px; 
  text-align: center; margin: 0px auto; color: #fff; border: 0px; border-radius: 3px;}
  .done-close:hover{ background: #2c7045; }

  .culb .ant-btn-primary{background: #5c5f66 !important; color: #fff;}
  .culb button:hover{background: #5c5f66 !important; color: #fff;}
  .left-menu-active{background: #45806c !important;}
  .culbb .ant-btn-primary{background: #5c5f66 !important; color: #fff;}
  .culbb button:hover{background: #5c5f66 !important; color: #fff;}
  
  .copyright-footer {color: white; text-align: right; font-size: 10px; margin: 5px;}
  .bity { height: 30px !important; font-size: 18px !important; 
     border: 0px; border-bottom: solid 1px ; background: initial; 
     border-bottom: solid 1px #cecece !important; margin-bottom: 10px;}
.bity input{font-size: 18px !important;}
.new-ched .ant-checkbox-checked .ant-checkbox-inner{background-color: #45806c !important;
  border-color: #45806c !important;}


.medl-hed{text-align:center; font-size:26px; color:#fff; 
  margin:40px auto 0px auto; width:96%; padding:5px 0px; display:block; background:#484b67;
  border-radius:6px;}
.but-spane{font-size: 20px; color: aliceblue; padding-bottom: 20px;}
.butt-lm { color: #fff; margin-top: 15px; padding-top: 5px; padding-bottom: 10px; text-align: left; 
  // border-top: solid 1px #505050;
  background:#696051;
}
.butt-lm img{ float: left; margin: 0px 10px;}
.butt-lm a{ color: #fff;}


// svg {
//   width: 100px;
//   display: block;
//   margin: 40px auto 0;
// }

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  &.circle {
    -webkit-animation: dash .9s ease-in-out;
    animation: dash .9s ease-in-out;
  }
  &.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash .9s .35s ease-in-out forwards;
    animation: dash .9s .35s ease-in-out forwards;
  }
  &.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check .9s .35s ease-in-out forwards;
    animation: dash-check .9s .35s ease-in-out forwards;
  }
}

p {
  text-align: center;
  margin: 20px 0 60px;
  font-size: 1.25em;
  &.success {
    color: #73AF55;
  }
  &.error {
    color: #D06079;
  }
}


@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

.hide-border .ant-modal-header{ border-bottom: 0px !important;}
.top-heade{ font-size: 20px;  padding: 0px 0px 20px 0px; text-align: center; font-weight: 400;}
.noty{font-size: 15px; font-weight: 400; padding: 0px 10px 20px 20px;}

@media only screen and (max-width:1350px){
  .top-heade{ font-size: 18px;  padding: 0px 0px 5px 0px; }
  .culb button{ height: 45px; font-size: 22px;}
  .mart{ height: 40px;}
  .cul-text{ font-size: 28px;}
  .culbb button{ height: 30px;}
  .noty{font-size: 15px; font-weight: 400; padding: 0px 10px 10px 20px;}
  .culbs button{ margin: 0px; height: 46px;}
  .culb button .doloers{ top: -12px;}
  
}
  @media only screen and (max-width:1250px){
  .culb button{font-size: 20px; height: 40px;}
  .slick-slide img{ 
    height: 100vh;
    // width: 100%;
  }
  .culb button .doloers{ top: -8px;}
  .medl-hed{ font-size: 18px ;}
  .but-spane{ padding-bottom: 120px;}
  .donation-bg{  
     background: url(/images/donation-bg1.jpg); 
    // background-size: cover;  background-repeat: no-repeat;
    // height: 100vh; background: #f2f2f2;
  
 }

}


.text-middle {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  width: 100%; text-align: center; 
}

.header-spin{ height: 100vh; width: 100%; 
  background: rgba(0, 0, 0, .6); 
  position: relative;
top: 0px; left: 0px;
z-index: 999; 
}




.dark-inss{ 
  //  background: #fff; 
  height: 100vh; 
  position: relative; color: #a0a2c0; 
  padding: 15px 0px 10px; 
background: url(../images/donationweb-bg.jpg) no-repeat;
background-size: 100%;
}

.dark-inss ul{ padding:10px 0px 0px; font-size: 16px;  list-style: none;}
 .dark-inss ul li{ list-style: none; padding: 10px 0px; cursor: pointer; 
  border-bottom: solid 0px #363749; border-radius: 50px;
  // -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.15);
  // box-shadow: 0 0 20px rgba(89, 102, 122, 0.15); 
 -webkit-box-shadow: 0px 10px 11px -13px rgba(171,169,171,1);
   -moz-box-shadow: 0px 10px 11px -13px rgba(171,169,171,1);
 box-shadow: 0px 10px 11px -13px rgba(171,169,171,1);
  
  // background: #fff;  
  width: 47%; float: left; margin: 10px 1.5%;
  color: #0f67d9; text-align: center; font-size: 18px; border: solid 1px #dfdfdf;
//   text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.14);
//  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
}
// .dark-inss div{padding-left: 25px;}
.dark-inss ul li:hover{ 
  // background: #e9ffd5; 
  background: linear-gradient(#ffffff, #f1f1f1); 
  cursor: pointer; }
.active-prject{ 
  // background: #f7f7f7; 
  background: linear-gradient(#ffffff, #f1f1f1); 
  color: #0f67d9;}

.dark-inss ul label{ margin: 0px; padding: 0px 15px; font-size: 20px;}

.dark-inss .ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #f8ab37;
  border-radius: 50%;
  visibility: hidden;
  -webkit-animation: antRadioEffect 0.36s ease-in-out;
  animation: antRadioEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  content: '';
}

.dark-inss .ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 8px;
  height: 8px;
  background-color: #da2c4d;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}

.dark-inss.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
  border-color: #f8ab37;
}

.medl-hedl{text-align:center; font-size:26px; color:#000000; 
  margin:0px auto 0px auto; width:96%; padding:5px 0px; display:block;
  border-radius:6px;}
.medl-hedl span{ border-bottom: solid 1px #87bc59;}

.top-headel{ font-size: 20px;  padding: 20px 0px 20px 0px; text-align: center; font-weight: 400;}
.top-headel span{ font-size: 30px;}
.active-prjectm{ background: linear-gradient(#ffffff, #f1f1f1) !important;  
  color: #000 !important; -webkit-box-shadow: 0px 10px 11px -10px rgba(158,158,158,1);
  -moz-box-shadow: 0px 10px 11px -10px rgba(158,158,158,1);
  box-shadow: 0px 10px 11px -10px rgba(158,158,158,1);}
.iner-head{ background: #f9f9fb; border-bottom: solid 1px #e4e4e4; padding-top: 15px; padding-bottom: 15px;}
.compan-me{ padding: 10px 0px 0px; font-size: 28px;}
.new-add{ font-size: 16px; padding-top: 5px;}
.donat-logo span{ height: 70px !important; width: 70px !important; line-height: 70px; }

.new-contaner{ position: relative;   height: 100vh; overflow-y: auto;}
  

.new-contaner1{   
  border: 0px  solid #f0f0f0 !important; 
 -webkit-box-shadow: 0px 7px 30px 0px rgba(235,235,235,1);
 -moz-box-shadow: 0px 7px 30px 0px rgba(235,235,235,1);
 box-shadow: 0px 7px 30px 0px rgba(235,235,235,1);
background: #fff;
border-radius: 10px; margin: 5% auto 0px; padding: 20px;  
// position: absolute;
// top: 50%;
// left: 50%;
// -ms-transform: translate(-50%, -50%);
// transform: translate(-50%, -50%);
width: 600px; 
}


.btn-light{
  background: #f2f2f2;
  background: -moz-linear-gradient(-45deg, #f2f2f2 0%, #f1f1f1 50%, #ffffff 51%, #ffffff 71%, #f6f8fb 100%);
  background: -webkit-linear-gradient(-45deg, #f2f2f2 0%,#f1f1f1 50%,#ffffff 51%,#ffffff 71%,#f6f8fb 100%);
  background: linear-gradient(135deg, #f2f2f2 0%,#f1f1f1 50%,#ffffff 51%,#ffffff 71%,#f6f8fb 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#f6f8fb',GradientType=1 );
  // color: #3f345f !important;
  background-size: 400% 400%;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  border: medium none;
}

.pb10{
padding-bottom: 10px;
}

.input-text-error {
  border: 1px solid #ff4d4f !important;

  &:hover {
    border: 1px solid #ff4d4f !important;
  }

  &:focus {
    border: 1px solid #ff4d4f !important;
  }
}

// @-webkit-keyframes AnimationName {
//   0%{
//       background-position:0% 31%
//   }
//   50%{
//       background-position:100% 70%
//   }
//   100%{
//       background-position:0% 31%
//   }
// }
// @-moz-keyframes AnimationName {
//   0%{
//       background-position:0% 31%
//   }
//   50%{
//       background-position:100% 70%
//   }
//   100%{
//       background-position:0% 31%
//   }
// }
// @keyframes AnimationName {
//   0%{
//       background-position:0% 31%
//   }
//   50%{
//       background-position:100% 70%
//   }
//   100%{
//       background-position:0% 31%
//   }
// }

.input-currency-control {
  position: relative;
}

.input-currency-control .currency-tag {
  background: #e7e7e7;
  height: 32px;
  line-height: 24px;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 40px;
  z-index: 9;
}
.lrtu input{ padding-left: 50px;}
.custom-dropdown-width .ant-select-arrow {
  margin-top: 2px;
}

.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 53%;
  right: 11px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}

.modal-custom-detailtr2 .ant-modal{ top: 20px;}
.modal-custom-detailtr2 .ant-modal-title{ text-align: center;}
.newregis .currency-tag{ padding: 3px 0px 0px;}
.newregis input{ padding-left: 50px;}
.pay-textty span{ padding-right: 20px;}
.tliu input{ font-size: 14px !important; text-align: left !important;}
.paycard .form-container{ padding: 0px 20px;}
.paycard .form-container input{  color: #000 !important;}
.paycard .ccicon{ top: 14%;}



::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    #333;
  opacity:  1;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
 color:    #333;
 opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
 color:    #333;
 opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color:    #333;
 opacity:  1;
}
::-ms-input-placeholder { /* Microsoft Edge */
 color:    #333;
 opacity:  1;
}

::placeholder { /* Most modern browsers support this now. */
 color:    #333;
 opacity:  1;
}

.dark-bluec{ background: #1f2029; height: 100vh; position: relative; color: rgb(235, 235, 235); border-left: solid 1px #484b67;}

.ltiy input{ padding-left: 45px;}
.ant-tooltip-inner{ z-index: 9999;}
.otpStyle div{padding-left: 0px;}


.active-prject-ple{ background: #090a0e; color: #5c5f66; }

.calcosp{ background: #f6f6f8; height: 100vh; padding: 15px 0px; overflow: auto;} 