$collpasedSidebarWidth: 46px;

$lightTextColor: #656b79;
$darkTextColor: #39414b;


$headerColor: #2b3d51;

$headerText: rgba(255, 255, 255, .5);
$headerHoverText: #fff;

$sidebarBackground: #000;
$sidebarHover: #5c5f66;

$sidebarText: #a1a4ab;
$sidebarHoverText: #c6c9cf;

$viewBackground: #e9ecf0;

$borderColor: #2d343d;
