.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.spinner {
  top: 25% !important;
}

.spinnerIcon > svg{
  font-size: 30px;
  color: grey;
}
.ant-spin-text{
  margin-top: 10px;
  color: grey;
}

.App-link {
  color: #09d3ac;
}

.action-menu-bars{
  color: #000000;
  background-color: #f3f3f3 !important;
  border-color: #f3f3f3 !important;
}

.ant-spin-dot{
  font-size: 30px !important;
}